.notification_table > div {
  width: 95em;
  font-size: 14px;
  padding: 1% 0;
}

.datePickerIp {
  border-radius: 30px;
  padding: 5px 20px 5px 15px;
  outline: none;
  border: 1px solid #dee2e6;
  width: 10em;
}
.close_btn {
  margin-bottom: -20px;
}
