.tableButton {
  background: rgb(0, 191, 179);
  color: rgb(255, 255, 255);
  font-weight: bold;
  float: right;
  height: 36px;
  font-size: 14px;
  text-shadow: none;
  min-width: max-content;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.tableButton:hover,
.tableButton:hover a {
  background: white;
  border-color: rgb(0, 191, 179) !important;
  color: rgb(0, 191, 179) !important;
}
