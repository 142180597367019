.config_tabs li a {
  color: #212529;
  font-weight: 400;
  font-size: 16px;
}
.config_tabs li:first-child {
  padding-left: 0px;
}
.cf_tab a:hover {
  color: var(--color-pink);
  /* border: none; */
  border-top: none;
  border-bottom: none;
  border-left: none;
}
.cf_link {
  background: transparent;
  border: none !important;
}
.config_tabs li a.active {
  border-bottom: 2px solid var(--color-pink) !important;
  color: var(--color-pink);
}
.cfbtns {
  display: flex;
  justify-content: center;
}
.cfbtns button {
  margin: 0 10px;
}

.searchWrapper {
  margin: 0 5px;
  float: right;
}
@media screen and (max-width: 767px) {
  .searchWrapper {
    float: none;
    margin-top: 5%;
    margin-left: 0px;
  }
}
