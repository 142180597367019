.file_selector {
  border: none;
  color: black;
  padding: 10px;
  border-radius: 28px;
}

.config_tabs li a {
  color: #212529;
  font-weight: 400;
  font-size: 16px;
}
.config_tabs li:first-child {
  padding-left: 0px;
}

/* .config_tabs li a:hover {
  color: #212529;
} */
/* .config_tabs ul > li > a.active {
  border-bottom: 1px solid var(--color-pink) !important;
} */
.cf_tab a:hover {
  color: var(--color-pink);
  /* border: none; */
  border-top: none;
  border-bottom: none;
  border-left: none;
}
.cf_link {
  background: transparent;
  border: none !important;
}
.config_tabs li a.active {
  /* border-bottom: 2px solid var(--color-pink) !important; */
  background-color: var(--color-pink) !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.error_text {
  text-align: center;
  font-weight: 500;
  color: #e43333;
}

.cron_form > div {
  width: 45em;
}
.cfbtns {
  display: flex;
  justify-content: center;
}
.cfbtns button {
  margin: 0 10px;
}
.triggerForm > div {
  width: 45em;
}

.triggerAction,
.triggerAction > button {
  margin: 5px !important;
}

.mob_action_btn_links > button {
  border-radius: 8px;
  height: 25px;
  font-size: 18px;
  padding: 15px 10px;
}

.normalTab {
  background: white !important;
  font-weight: 400 !important;
  border-radius: 15px 15px 0 0 !important;
}

.small_btn {
  background: rgb(0, 191, 179);
  color: rgb(255, 255, 255);
  font-weight: bold;
  /* height: 40px; */
  font-size: 15px;
  text-shadow: none;
  min-width: 90px;
  border-radius: 50px;
  padding: 8px 10px;
  float: right;
  margin: 1% 0;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 6.2rem;
  border: 1px solid rgb(0, 191, 179);
  cursor: pointer;
}
.small_btn:hover {
  color: rgb(0, 191, 179);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 191, 179);
}
.distSelect > div > div > div {
  width: auto !important;
}
.errorMsg {
  color: #cc0033;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-shadow: 1px 1px rgba(250, 250, 250, 0.3);
  display: block;
}

@media screen and (max-width: 768px) {
  .cf_link {
    padding: 3px 10px;
  }
}
