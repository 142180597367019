.action_btn_links {
  margin: 5px;
}
.action_icons {
  width: 30px;
  height: 28px;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.edit_icon {
  background: #037bfe;
  /* ffdc00 */
  color: #fff;
}
.file_icon {
  background: #037bfe;
  color: #fff;
}
.pause_icons {
  background: #ffdc00;
  color: #000;
}
.delete_icon {
  background: #e51111;
  color: #fff;
}

.add_bookmark {
  background: #ff406b;
  color: #fff;
}

.analysis_icon {
  background: #037bfe;
  color: #fff;
}
.supplyDmd_icon {
  background: #0d9b09;
  color: #fff;
}
.download_icon {
  background: #0d9b09;
  color: #fff;
}

.cron_icons {
  background: #5080c5;
  /* ffdc00 */
  color: #fff;
}

@media screen and (max-width: 991px) {
  .action_btn_links {
    margin: 4px;
  }
  .action_icons {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
}
