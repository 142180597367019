.searchWrapper {
  /* display: inline-block; */
  /* margin: 0 5px; */
  /* float: right; */
}
@media screen and (max-width: 767px) {
  .searchWrapper {
    float: none;
    margin-top: 5%;
    margin-left: 0px;
  }
}
