.bookmark_wrapper {
  overflow: hidden;
}
.notification_table > div {
  width: 95em;
  padding: 1% 0;
  font-size: 14px;
}

:global(.datePickerIp) {
  border-radius: 30px;
  padding: 5px 20px 5px 15px;
  outline: none;
  border: 1px solid #dee2e6;
  width: 10em;
}
.close_btn {
  margin-bottom: -20px;
}
