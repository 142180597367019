.platform {
  z-index: 0;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, #db3390, #d73131c2);
  padding: 30px 0;
}

.pfSelection {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  background: #fff;
  padding: 35px;
  border-radius: 30px;
  box-shadow: 0 0 35px rgb(0 0 0 / 10%);
  text-align: left;
  position: relative;
  z-index: 0;
}
.pfHeading h1 {
  font-size: 32px;
  margin-top: 20px;
}

.pfBtn {
  color: #a20067;
  border-color: #a20067;
  /* margin: 0 3% ; */
  width: 8em;
}
.pfBtn:hover {
  color: #fff !important;
  background-color: #a20067 !important;
  /* margin: 3%; */
  /* width: 40%; */
}
.pfBtns {
  display: flex;
  justify-content: center;
}
.pfBtnElm {
  margin: 3%;
}
