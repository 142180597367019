.badgeSuccess {
  color: #fff;
  background-color: #28a745 !important;
}
.badgeDanger {
  color: #fff;
  background-color: #dc3545;
}

.actionBtnLinks {
  margin: 5px;
}
.actionBtnLinks svg {
  width: 30px;
  height: 28px;
  padding: 5px;
  border-radius: 5px;
}

.actionBtnLinks.eyelink svg {
  background: #ffdc00;
  color: #000;
  padding: 5px;
  border-radius: 5px;
}
.actionBtnLinks.edit svg {
  background: #0d9b09;
  color: #fff;
}
.triggerForm > div {
  width: 45em;
}

.triggerAction,
.triggerAction > button {
  margin: 5px !important;
}

.filter_wrapper {
  align-items: center;
}
.searchWrapper > input {
  margin-bottom: 0px !important;
}

.close_btn {
  margin-bottom: -20px;
}
.notification_table > div {
  width: 55em;
}
.cron_form > div {
  width: 45em;
}

#statusFilter {
  border-radius: 10px;
  border-color: rgb(190, 198, 205);
  /* max-width: 30%; */
  color: var(--bs-table-color);
  border: none;
  font-size: 14px !important;
  max-width: 70%;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .setWidth {
    max-width: 28%;
    width: 28%;
  }
}
