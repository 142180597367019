@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import "colors.css";

body {
  background-color: #f7f9fa !important;
  font-size: 1rem;
}

.active {
  color: var(--color-pink) !important;
}

.container {
  max-width: 90% !important;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}
.card-box {
  background-color: var(--color-white);
  padding: 1.25rem;
  box-shadow: 0 0 35px 0 rgb(73 80 87 / 15%);
  margin-bottom: 24px;
  border-radius: 0.25rem;
}

/* Page Title Start */
.header-title {
  font-size: 16px;
  margin: 0 0px 3% 0 !important;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  color: #343a40;
}

/* Table CSS */
td {
  padding: 0.75rem !important;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .no-gutter-mob {
    --bs-gutter-x: 0 !important;
  }
}

div#swal2-html-container {
  overflow: hidden;
  text-align: left;
}

/* Sweetalert Styling */

button.swal2-confirm.swal2-styled {
  border-radius: 32px;
  background: var(--color-pink) !important;
  width: auto;
  font-size: 18px;
  padding: 0.425em 1em;
  border: 1px solid var(--color-pink) !important;
}
button.swal2-confirm.swal2-styled:hover {
  border-radius: 32px;
  background: var(--color-white) !important;
  color: var(--color-pink) !important;
  border: 1px solid var(--color-pink) !important;
}
/* Sweetalert Styling End */

/* Action Links removed */
.action-links > button.btn.tablebutton_tableButton__W8p0z a {
  text-decoration: none;
  color: black;
}

.action-links > .tablebutton_tableButton__W8p0z {
  background: none;
  color: black;
  font-weight: bold;
  float: none !important;
  height: 33px;
  font-size: 12px;
  text-shadow: none;
  min-width: max-content;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  margin: 0 10px;
}

.action-links.col-12 {
  text-align: center;
}

/* cron form */

.react-js-cron-select-dropdown-period {
  z-index: 99999999 !important;
}
.react-js-cron-custom-select-dropdown {
  z-index: 99999999 !important;
}
.swal2-actions {
  z-index: 0;
}
.centered-text:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: #ccc;
}

.centered-text {
  position: relative;
  text-align: center;
  margin-top: 5%;
}

.centered-text span {
  display: inline-block;
  padding: 0 10px;
  background: #fff;
}

.addNewBtn {
  float: right;
}
.addNewBtn svg {
  background-color: var(--color-green);
  color: var(--color-white);
}

.react-datepicker__month-read-view {
  margin: 0px 20px;
}
span.react-datepicker__month-read-view--down-arrow {
  margin: 3px 0;
}
span.react-datepicker__year-read-view--down-arrow {
  margin: 3px 0;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 5%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  display: block;
  position: relative;
  bottom: 15%;
}

.table-responsive {
  overflow-x: hidden;
}

/* .react-table {
  width: 100%;
  border-collapse: collapse;
}
.react-table td,
.react-table th {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  font-size: 16px;
}

.react-table th {
  background-color: darkblue;
  color: #ffffff;
}

.react-table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
} */
@media (max-width: 767px) {
  .react-table thead {
    display: none;
  }

  .react-table,
  .react-table tbody,
  .react-table tr,
  .react-table td {
    display: block;
    width: 100%;
  }
  .react-table tr {
    margin-bottom: 15px;
  }
  .react-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }
  .react-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
}

.customAccordion .accordion-button:not(.collapsed) {
  background-color: #212529 !important;
  color: white !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}
.accordion-item {
  box-shadow: 0 0 35px 0 rgb(73 80 87 / 15%);
  margin: 3px 0;
}
