.submit_btn {
  background: var(--color-pink);
  color: rgb(255, 255, 255) !important;
  font-weight: bold !important;
  height: 40px;
  font-size: 15px !important;
  text-shadow: none;
  min-width: 90px;
  border-radius: 50px !important;
  /* margin: 0 auto;
  display: block; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.submit_btn:hover {
  background: rgb(255, 255, 255) !important ;
  color: var(--color-pink) !important;
  border-color: var(--color-pink) !important;
}

.tableButton {
  background: rgb(0, 191, 179) !important;
  color: rgb(255, 255, 255) !important;
  font-weight: bold !important;
  height: 40px;
  font-size: 15px !important;
  text-shadow: none;
  min-width: 90px;
  border-radius: 50px !important;
  /* margin: 0 auto;
  display: block; */
}

.tableButton:hover {
  background: white !important;
  border-color: rgb(0, 191, 179) !important;
  color: rgb(0, 191, 179) !important;
}
