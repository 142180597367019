.searchWrapper {
  /* display: inline-block; */
  margin: 0 5px;
  /* float: right; */
}

.datePickerIp {
  /* border-radius: 4px;
  padding: 5px;
  outline: none;
  border: 1px solid #dee2e6;
  width: 10em; */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.datePickerWrapper button:first-child {
  position: inherit !important;
  padding: 5px;
}
.datePickerWrapper {
  display: flex;
}
.datePickerWrapper label {
  white-space: nowrap;
  margin: 5px 0;
  margin-right: 7px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 400;
}

.datePickerWrapper input {
  position: inherit !important;
  width: -webkit-fill-available;
}

.distName {
  white-space: nowrap;
  align-items: center;
  margin: 5px 0;
  margin-right: 7px;
  margin-right: 21px !important;
  font-size: 16px;
  font-weight: 400;
  width: -webkit-fill-available;
  display: flex;
}

.filterWrapper {
  display: flex;
  align-items: center;
}

.distName > div {
  position: relative;
  box-sizing: border-box;
  width: -webkit-fill-available !important;
  margin-left: 5px;
}
.asyncSel {
}
.lineTable > div {
  width: 80em;
}
