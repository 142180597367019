.file_selector {
  border: none;
  color: black;
  padding: 10px;
  border-radius: 28px;
}

.config_tabs li a {
  color: #212529;
  font-weight: 400;
  font-size: 16px;
}
.config_tabs li:first-child {
  padding-left: 0px;
}

/* .config_tabs li a:hover {
  color: #212529;
} */
/* .config_tabs ul > li > a.active {
  border-bottom: 1px solid var(--color-pink) !important;
} */
.cf_tab a:hover {
  color: var(--color-pink);
  /* border: none; */
  border-top: none;
  border-bottom: none;
  border-left: none;
}
.cf_link {
  background: transparent;
  border: none !important;
}
.config_tabs li a.active {
  border-bottom: 2px solid var(--color-pink) !important;
  color: var(--color-pink);
}
.error_text {
  text-align: center;
  font-weight: 500;
  color: #e43333;
}

.cron_form > div {
  width: 45em;
}
.cfbtns {
  display: flex;
  justify-content: center;
}
.cfbtns button {
  margin: 0 10px;
}
.triggerForm > div {
  width: 45em;
}

.triggerAction,
.triggerAction > button {
  margin: 5px !important;
}

.mob_action_btn_links > button {
  border-radius: 8px;
  height: 25px;
  font-size: 18px;
  padding: 15px 10px;
}

@media screen and (max-width: 768px) {
  .cf_link {
    padding: 3px 10px;
  }
}
