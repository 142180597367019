@import "../../colors.css";

.navbar {
  background-color: var(--color-white) !important;
  box-shadow: 0 0 35px 0 rgb(73 80 87 / 15%);
  height: 85px;
  left: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.brand_name {
  display: initial;
  font-size: 30px;
  margin: 0 15px;
  vertical-align: middle;
  color: var(--color-grey);
}

.dfp_navlink {
  /* font-weight: 500; */
  color: var(--color-grey);
  font-size: 1.1rem !important;
}

.tool_link {
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
}
.active {
  font-weight: 500;
  color: #fff !important;
}

.align_left {
  padding-left: 0px !important;
}
.top_nav_active {
  color: #a20067 !important;
  font-weight: 500 !important;
}

.regionSel,
.mobile_regionSel {
  border: none;
  background: transparent;
  color: grey;
  color: rgba(255, 255, 255, 0.55);
  font-size: 1rem;
  /* border: 1px solid rgba(255,255,255,.55); */
  padding: 5px 10px;
  max-width: 173px;
  border-radius: 15px;
}

.regionSel option,
.mobile_regionSel option {
  color: #595858 !important;
}
.sec_menu_links a {
  font-size: 1rem !important;
}
.mobile_regionSel {
  display: none;
}
.main_menu {
  background-color: var(--color-white) !important;
}
@media screen and (max-width: 767px) {
  .brand_name {
    font-size: 20px;
    margin: 0 10px;
  }
  .mobile_regionSel {
    padding: 0;
    display: block;
  }
  .regionSel {
    display: none;
  }
  .mob_fullWidth {
    max-width: 100% !important;
  }
  .tool_link {
    padding: 0.5rem 0rem !important;
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .mobile_regionSel {
    padding: 0;
    display: block;
  }
  .regionSel {
    display: none;
  }
  .mob_fullWidth {
    max-width: 100% !important;
  }
}
