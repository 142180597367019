.store_details {
  font-size: 14px;
}
.store_act_btns {
}

@media screen and (max-width: 768px) {
  .store_act_btns {
    flex-direction: column;
  }
  .store_act_btns > button {
    margin: 5px 10px !important;
  }
}
